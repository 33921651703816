import Utils from '../Utils'
import anime from 'animejs'

export default class BackgroundNoise {
  getDefaultOptions() {
    return {
      defaultColors: {
        background: '#ffffff',
        text: '#000000'
      },
      duration: 500,
      easing: 'easeInOutQuart',
      noise: {
        animated: true,
        frames: 10,
        fps: 40,
        color: 0xff000000,
        size: 1,
      },
      canvasStyle: {
        position: 'fixed',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '120%',
        height: '120%',
        margin: 'auto',
        zIndex: -1,
        pointerEvents: 'none'
      }
    }
  }

  constructor(options) {
    this.options = Utils.objectMergeDeep(this.getDefaultOptions(), options)
    this.states = {
      colors: {},
      initialColors: {
        background: this.options.defaultColors.background,
        text: this.options.defaultColors.text,
      }
    }

    if (this.options.noise) {
      const canvas = document.createElement('canvas')
      this.canvas = canvas
      this.context = canvas.getContext('2d')

      let css = this.options.canvasStyle
      css['-webkit-transform'] = css['-ms-transform'] = css.transform
      Utils.css(canvas, css)

      document.body.appendChild(canvas)
      this.setupNoise()
    }

    this.bindEvents()
  }

  destroy() {
    this.unbindEvents()
    document.body.removeChild(this.canvas)
  }

  setInitialColors(animated = true) {
    this.setColors(this.states.initialColors.background, this.states.initialColors.text, animated)
  }

  setColors(background, text, animated = true) {
    if (!animated) {
      document.documentElement.style.backgroundColor = this.states.colors.background = background
      document.documentElement.style.color = this.states.colors.text = text
      this.onAnimationComplete(background, text)
      return
    }

    this.animate(background, text)
  }

  animate(backgroundColor, color) {
    anime.remove(document.documentElement)
    return anime({
      targets: document.documentElement,
      backgroundColor: backgroundColor,
      color: color,
      duration: this.options.duration,
      easing: this.options.easing,
      complete: this.onAnimationComplete.bind(this, backgroundColor, color)
    })
  }

  onAnimationComplete(newBackground, newText) {
    this.states.colors.background = newBackground
    this.states.colors.text = newText
  }

  setupNoise() {
    const ww = window.innerWidth, wh = window.innerHeight

    this.noiseFrames = []
    this.frame = 0
    this.loopTimeout

    this.canvas.width = (ww + (20 * ww / 100)) / this.options.noise.size
    this.canvas.height = (wh + (20 * wh / 100)) / this.options.noise.size

    for (let i = 0; i < this.options.noise.frames; i++) {
      this.createNoise()
    }

    if (this.options.noise.animated && this.options.noise.frames > 1) {
      this.loopNoise()
    } else {
      this.paintNoise()
    }

  }

  createNoise() {
    const idata = this.context.createImageData(this.canvas.width, this.canvas.height)
    const buffer32 = new Uint32Array(idata.data.buffer)
    const len = buffer32.length

    for (let i = 0; i < len; i++) {
      if (Math.random() < 0.5) {
        buffer32[i] = this.options.noise.color
      }
    }

    this.noiseFrames.push(idata)
  }

  paintNoise(frame = this.frame) {

    if (this.frame === this.options.noise.frames - 1) {
      this.frame = 0
    } else {
      this.frame++
    }

    this.context.putImageData(this.noiseFrames[this.frame], 0, 0)
  }

  loopNoise() {
    this.paintNoise()

    this.loopTimeout = window.setTimeout(() => {
      window.requestAnimationFrame(this.loopNoise.bind(this))
    }, (1000 / this.options.noise.fps))
  }

  bindEvents() {
    this.onResizeListener = Utils.throttle(this.onResize.bind(this), 200)
    window.addEventListener('resize', this.onResizeListener)
  }

  unbindEvents() {
    this.canvas.removeEventListener('resize', this.onResizeListener)
    delete this.onResizeListener
  }

  onResize(event) {
    this.options.noise && this.setupNoise()
  }
}