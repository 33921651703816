import CommonView from './CommonView'
import Utils from '../Utils'
import Gallery from '../components/Gallery'
import Bricks from 'bricks.js'

let ProjectView = CommonView.extend({
  namespace: 'project',

  onEnter: function () {
    // The new Container is ready and attached to the DOM.
    CommonView.onEnter.apply(this)

    Utils.lazyloadEmbed(this.container)

    const DOMProject = this.container.querySelector('.Project')
    if (window.innerWidth < mediaQueries.tablet) {
      const DOMTypographicTitle = DOMProject.querySelector('.Project-typographicTitle')
      if (DOMTypographicTitle) DOMTypographicTitle.dataset.afterTransform = 'rotate(-90deg)'
    }

    const DOMGalleryCascade = DOMProject.querySelector('.Gallery--cascade')
    if (DOMGalleryCascade) {
      let gutter = 20
      this.bricks = new Bricks({
        container: DOMGalleryCascade.children[0],
        packed: 'data-packed',
        sizes: [
          {columns: 1, gutter: gutter},
          {mq: mediaQueries.tablet + 'px', columns: 2, gutter: gutter},
          // { mq: mediaQueries.desktop + 'px', columns: 2, gutter: gutter },
          {mq: (mediaQueries.largeDesktop - 100) + 'px', columns: 3, gutter: gutter}
        ],
        position: false
      })
      this.bricks.resize()
      this.bricks.pack()

      if (window.innerWidth >= mediaQueries.largePhone) this.gallery = new Gallery(DOMGalleryCascade.children[0])
    }
  },

  onEnterCompleted: function () {
    // The Transition has just finished.
    CommonView.onEnterCompleted.apply(this)
  },

  onLeave: function () {
    // A new Transition toward a new page has just started.
    CommonView.onLeave.apply(this)
    if (this.bricks) {
      this.bricks.resize(false)
      delete this.bricks
    }

    if (this.gallery) {
      this.gallery.destroy()
      delete this.gallery
    }
  },

  onLeaveCompleted: function () {
    // The Container has just been removed from the DOM.
    CommonView.onLeaveCompleted.apply(this)
  }
})

export default ProjectView
