import objectFitImages from 'object-fit-images'

const Utils = {
  // region Responsive
  ////////////////////////////////////

  getDeviceType: () => {
    let device = deviceList.smallPhone
    if (window.innerWidth >= mediaQueries.mediumPhone) device = deviceList.mediumPhone
    if (window.innerWidth >= mediaQueries.largePhone) device = deviceList.largePhone
    if (window.innerWidth >= mediaQueries.tablet) device = deviceList.tablet
    if (window.innerWidth >= mediaQueries.desktop) device = deviceList.desktop
    if (window.innerWidth >= mediaQueries.largeDesktop) device = deviceList.largeDesktop
    return device
  },

  setDeviceType: device => document.body.dataset.deviceType = device,

  updateDeviceType: () => Utils.setDeviceType(Utils.getDeviceType()),

  isDeviceType: device => Utils.getDeviceType() === device,

  isTouchDevice: () => 'ontouchstart' in window /*Works on most browsers*/
    || navigator.maxTouchPoints, /*Works on IE10/11 and Surface*/

  isRetina: () => {
    const mediaQuery = '(-webkit-min-device-pixel-ratio: 1.5),\
                      (min--moz-device-pixel-ratio: 1.5),\
                      (-o-min-device-pixel-ratio: 3/2),\
                      (min-resolution: 1.5dppx)'

    return window.devicePixelRatio > 1 || (window.matchMedia && window.matchMedia(mediaQuery).matches)
  },
  // endregion

  // region A11y
  ////////////////////////////////////

  setCursor: cursor => Utils.css(document.documentElement, {cursor}),

  // region Events
  ////////////////////////////////////

  debounce: (callback, delay = 100) => {
    let timeout
    return function (...args) {
      clearTimeout(timeout)
      timeout = setTimeout(() => {
        callback.apply(this, args)
      }, delay)
    }
  },

  throttle: (callback, delay = 100) => {
    let timer = null
    return function (...args) {
      if (timer === null) {
        timer = setTimeout(() => {
          callback.apply(this, args)
          timer = null
        }, delay)
      }
    }
  },
  // endregion

  // region HTML
  ////////////////////////////////////

  spaceToNbsp: text => text.replace(/\s/g, '&nbsp;'),
  // endregion

  // region Style
  ////////////////////////////////////

  css: (el, styles) => {
    for (let [property, value] of Object.entries(styles)) {
      el.style[property] = value
    }
  },

  hexToRgb: (hexValue) => {
    const rgx = /^#?([a-f\d])([a-f\d])([a-f\d])$/i
    const hex = hexValue.replace(rgx, (m, r, g, b) => r + r + g + g + b + b)
    const rgb = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
    const r = parseInt(rgb[1], 16)
    const g = parseInt(rgb[2], 16)
    const b = parseInt(rgb[3], 16)
    return `rgb(${r},${g},${b})`
  },
  // endregion

  // region Images
  ////////////////////////////////////

  loadImage: (src, callback) => {
    let image = new Image()
    image.onload = event => callback && callback(event)
    image.src = src
  },

  polyfillImagesFit: () => {
    const images = document.querySelectorAll('img.ob-fit-image')
    objectFitImages(images)
  },
  // endregion

  // region HTTP
  ////////////////////////////////////

  get: (url) => {
    return new Promise((resolve, reject) => {
      let req = new XMLHttpRequest()
      req.open('GET', url)
      req.onload = () => {
        if (req.status === 200) resolve(req.response)
        else reject(new Error(req.statusText))
      }
      req.onerror = () => reject(new Error('Network error'))
      req.send()
    })
  },
  // endregion

  // region Maths
  ////////////////////////////////////
  // endregion

  // region Animations
  ////////////////////////////////////

  easing: (name) => {
    switch (name) {
      case 'in-quad':
        return 'cubic-bezier(0.550, 0.085, 0.680, 0.530)'
        break
      case 'in-cubic':
        return 'cubic-bezier(0.550, 0.055, 0.675, 0.190)'
        break
      case 'in-quart':
        return 'cubic-bezier(0.895, 0.030, 0.685, 0.220)'
        break
      case 'in-quint':
        return 'cubic-bezier(0.755, 0.050, 0.855, 0.060)'
        break
      case 'in-sine':
        return 'cubic-bezier(0.470, 0.000, 0.745, 0.715)'
        break
      case 'in-expo':
        return 'cubic-bezier(0.950, 0.050, 0.795, 0.035)'
        break
      case 'in-circ':
        return 'cubic-bezier(0.600, 0.040, 0.980, 0.335)'
        break
      case 'in-back':
        return 'cubic-bezier(0.600, -0.280, 0.735, 0.045)'
        break
      case 'out-quad':
        return 'cubic-bezier(0.250, 0.460, 0.450, 0.940)'
        break
      case 'out-cubic':
        return 'cubic-bezier(0.215, 0.610, 0.355, 1.000)'
        break
      case 'out-quart':
        return 'cubic-bezier(0.165, 0.840, 0.440, 1.000)'
        break
      case 'out-quint':
        return 'cubic-bezier(0.230, 1.000, 0.320, 1.000)'
        break
      case 'out-sine':
        return 'cubic-bezier(0.390, 0.575, 0.565, 1.000)'
        break
      case 'out-expo':
        return 'cubic-bezier(0.190, 1.000, 0.220, 1.000)'
        break
      case 'out-circ':
        return 'cubic-bezier(0.075, 0.820, 0.165, 1.000)'
        break
      case 'out-back':
        return 'cubic-bezier(0.175, 0.885, 0.320, 1.275)'
        break
      case 'in-out-quad':
        return 'cubic-bezier(0.455, 0.030, 0.515, 0.955)'
        break
      case 'in-out-cubic':
        return 'cubic-bezier(0.645, 0.045, 0.355, 1.000)'
        break
      case 'in-out-quart':
        return 'cubic-bezier(0.770, 0.000, 0.175, 1.000)'
        break
      case 'in-out-quint':
        return 'cubic-bezier(0.860, 0.000, 0.070, 1.000)'
        break
      case 'in-out-sine':
        return 'cubic-bezier(0.445, 0.050, 0.550, 0.950)'
        break
      case 'in-out-expo':
        return 'cubic-bezier(1.000, 0.000, 0.000, 1.000)'
        break
      case 'in-out-circ':
        return 'cubic-bezier(0.785, 0.135, 0.150, 0.860)'
        break
      case 'in-out-back':
        return 'cubic-bezier(0.680, -0.550, 0.265, 1.550)'
        break
      default:
        return 'ease'
    }
  },

  wait: duration => new Promise(resolve => setTimeout(resolve, duration)),
  // endregion

  // region Browsers
  ////////////////////////////////////
  isFirefox: () => navigator.userAgent.toLowerCase().indexOf('firefox') > -1,
  // endregion

  // region Misc
  ////////////////////////////////////

  getMousePos: (event) => {
    if (!event) return
    let posX = 0
    let posY = 0
    if (event.pageX || event.pageY) {
      posX = event.pageX
      posY = event.pageY
    } else if (event.clientX || event.clientY) {
      posX = event.clientX + document.body.scrollLeft + document.documentElement.scrollLeft
      posY = event.clientY + document.body.scrollTop + document.documentElement.scrollTop
    }
    return {x: posX, y: posY}
  },

  shuffleArray: (array) => {
    for (let i = array.length - 1; i > 0; i--) {
      let j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]]
    }
    return array
  },

  isObject: item => (item && typeof item === 'object' && !Array.isArray(item)),

  objectMergeDeep: (target, source) => {
    let output = Object.assign({}, target)
    if (Utils.isObject(target) && Utils.isObject(source)) {
      Object.keys(source).forEach(key => {
        if (Utils.isObject(source[key])) {
          if (!(key in target))
            Object.assign(output, {[key]: source[key]})
          else
            output[key] = Utils.objectMergeDeep(target[key], source[key])
        } else {
          Object.assign(output, {[key]: source[key]})
        }
      })
    }
    return output
  },

  copyToClipboard: (element) => {
    const input = document.createElement('input')
    element.appendChild(input)
    input.value = element.innerText.toLowerCase()
    input.select()

    if (document.execCommand('copy')) {
      element.classList.add('is-copied')

      let temp = setInterval(() => {
        element.classList.remove('is-copied')
        clearInterval(temp)
      }, 3000)

    } else {
      console.info('document.execCommand went wrong…')
    }
    element.removeChild(input)

    return false
  },

  registerTextToCopy: () => {
    const textsToCopy = document.querySelectorAll('.TextToCopy')
    if (!textsToCopy) return
    Array.from(textsToCopy)
      .map(textToCopy => textToCopy.addEventListener('click', Utils.copyToClipboard.bind(this, textToCopy)))
  },

  lazyloadEmbed: (element) => {
    let container = element || document
    const thumbs = container.getElementsByClassName('embed__thumb')
    Array.from(thumbs).map((thumb) => {
      thumb.addEventListener('click', function () {
        const wrapper = this.parentNode
        const embed = wrapper.children[0]
        embed.src = embed.dataset.src
        wrapper.removeChild(this)
      })
    })
  },

  resetTypographicTitlePos: (element) => {
    if (!element) return
    const transform = window.innerWidth < mediaQueries.tablet ? 'rotate(-90deg)' : 'none'
    Utils.css(element, {transform, webkitTransform: transform})
  }
  // endregion
}

export default Utils