import anime from 'animejs'
import charming from 'charming'

(function () {
  document.head.innerHTML += `
    <style>
    /* Style for charmer */
    .charmer span > span {
        display: inline-block;
        min-width: 0.25em;
    }
    .charmer-new > span { opacity: 0; }
    </style>`
})()

export default function (element, newValue, options) {
  if (!element || !newValue) {
    throw 'Charmer needs an element and a new value'
  }

  const defaultOptions = {
    animated: true,
    duration: 250,
    delayLetter: 20,
    distance: 20,
    maxScale: 1.4,
    alignTo: 'top',
    charming: {classPrefix: ''},
    complete: null
  }

  options = Object.assign(defaultOptions, options)

  function setup() {
    element.classList.add('charmer')
    let cssPosition = getComputedStyle(element).position
    if (cssPosition != 'absolute' && cssPosition != 'fixed') {
      element.style.position = 'relative'
    }
    element.innerHTML = `<span class="charmer-current">${element.innerHTML}</span>`
    charming(element.childNodes[0], options.charming)
  }

  function clear() {
    let childs = Array.from(element.childNodes)
    const totalChild = childs.length
    let i = 1
    childs.map(child => {
      try {
        if (i == totalChild) {
          child.classList.remove('charmer-new')
          child.classList.add('charmer-current')
        } else {
          child.remove()
        }
        i++
      } catch (error) {
        console.error('Charmer.js:' + error)
      }
    })
  }

  if (!element.querySelector('span.charmer-current')) setup()

  if (element.childNodes.length > 1) {
    // fix if charmer is already animating
    element.childNodes[1].style.opacity = 0
    element.childNodes[1].style.position = 'absolute'
  }

  // Setup old element
  let oldEl = element.childNodes[0]
  oldEl.style.position = 'absolute'
  oldEl.style[options.alignTo] = 0
  let oldElLetters = Array.from(oldEl.childNodes).reverse()

  // Setup new element
  let newEl = document.createElement('span')
  newEl.classList.add('charmer-new')
  newEl.innerHTML = newValue
  charming(newEl, options.charming)
  let newElLetters = Array.from(newEl.childNodes)
  element.append(newEl)

  // Play animation
  const duration = options.duration,
    delayLetter = options.delayLetter,
    distance = options.distance,
    maxScale = options.maxScale

  let timeline = anime.timeline({
    complete: () => {
      clear()
      options.complete && options.complete()
    }
  })

  timeline
    .add({
      targets: oldElLetters,
      delay: (t, i, l) => (l - i - 1) * delayLetter,
      translateY: {
        value: [0, -distance],
        duration: duration,
        easing: 'easeInQuad'
      },
      translateZ: 0,
      scaleY: {value: [1, maxScale], duration: duration, easing: 'easeInQuad'},
      opacity: {value: [1, 0], duration: duration, easing: 'linear'}
    })
    .add({
      targets: newElLetters,
      offset: 0,
      delay: (t, i) => i * delayLetter + duration,
      translateY: {
        value: [distance, 0],
        duration: duration,
        easing: 'easeOutQuad'
      },
      translateZ: 0,
      scaleY: {value: [maxScale, 1], duration: duration, easing: 'easeOutQuad'},
      opacity: {value: [0, 1], duration: duration, easing: 'linear'}
    })

  let result = {
    timeline: timeline,
    isPlaying: () => result.timeline && result.timeline.began && !result.timeline.completed,
    element: element
  }

  return result
}