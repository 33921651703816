import none from './none'
import fade from './fade'
import projectsToProject from './projectsToProject'
import projectToProject from './projectToProject'
import projectToProjects from './projectToProjects'
import contactToProject from './contactToProject'
import contactToProjects from './contactToProjects'

export default {
    home: {
        projects: none,
        project: projectsToProject,
    },
    projects: {
        home: none,
        project: projectsToProject,
    },
    project: {
        home: projectToProjects,
        projects: projectToProjects,
        project: projectToProject,
    },
    contact: {
        home: contactToProjects,
        projects: contactToProjects,
        project: contactToProject,
    },

    // Default transition
    default: fade
}