import Utils from '../Utils'

function fade(oldContainer, newContainer, done) {
  function start() {
    animateOut()
      .then(animateIn)
      .then(done)
  }

  function animateOut() {
    return new Promise((resolve, reject) => {
      Utils.css(oldContainer, {position: 'absolute', width: '100%'})
      resolve()
    })
  }

  function animateIn() {
    return new Promise((resolve, reject) => {
      oldContainer.style.display = 'none'
      Utils.css(newContainer, {visibility: 'visible'})
      resolve()
    })
  }

  return start()
}

export default fade