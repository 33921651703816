import Utils from '../Utils'
import Layzr from 'layzr.js'
import anime from 'animejs'

class Lazyload {
  constructor() {
    this.instance = new Layzr({
      normal: 'data-normal',
      retina: 'data-retina',
      srcset: 'data-srcset',
      threshold: 0
    })

    this.instance.on('src:before', this.beforeLoad.bind(this))

    return this.instance
  }

  beforeLoad(image) {
    image.style.opacity = 0

    switch (image.parentNode.dataset.lazyAnimation) {
      case 'none':
        this.none(image)
        break
      case 'reveal':
        this.reveal(image)
        break
      case 'scaleUp':
        this.scaleUp(image)
        break
      case 'fade':
      default:
        this.fadeIn(image)
        break
    }
  }

  none(image) {
    let imageLoading = new Promise((resolve, reject) => image.onload = resolve)
    imageLoading.then(event => Utils.css(event.target, {paddingTop: null, height: null, opacity: 1}))
  }

  reveal(image) {
    let revealer = image.nextElementSibling
    if (!revealer.classList.contains('Lazy-revealer')) {
      this.fadeIn(image)
      return
    }

    let animationIn = new Promise((resolve, reject) => {
      anime({
        targets: revealer,
        scaleX: [0, 1],
        translateZ: 0,
        easing: 'easeInOutQuint',
        duration: 500,
        begin: () => Utils.css(revealer, {transformOrigin: '0 50%', opacity: 1}),
        complete: resolve
      })
    })

    let imageLoading = new Promise((resolve, reject) => image.onload = () => {
      Utils.css(image, {paddingTop: null, height: null})
      resolve()
    })

    let animationOut = (image, revealer) => {
      Utils.css(revealer, {transformOrigin: '100% 50%'})
      anime({
        targets: image,
        opacity: [0, 1],
        easing: 'easeOutQuint',
        duration: 500
      })
      anime({
        targets: revealer,
        scaleX: 0,
        translateZ: 0,
        easing: 'easeInOutQuint',
        duration: 500,
        delay: 500,
        complete: () => revealer.remove()
      })
    }

    Promise
      .all([animationIn, imageLoading])
      .then(animationOut.bind(this, image, revealer))
  }

  scaleUp(image) {
    let imageLoading = new Promise((resolve, reject) => image.onload = resolve)

    imageLoading.then(event => {
      Utils.css(event.target, {paddingTop: null, height: null})
      anime({
        targets: event.target,
        opacity: [0, 1],
        scale: [0, 1],
        translateZ: 0,
        easing: 'easeOutQuad',
        duration: 500
      })
    })
  }

  fadeIn(image) {
    let imageLoading = new Promise((resolve, reject) => image.onload = resolve)

    imageLoading.then(event => {
      Utils.css(event.target, {paddingTop: null, height: null})
      anime({
        targets: event.target,
        opacity: [0, 1],
        easing: 'easeOutQuad',
        duration: 500
      })
    })
  }
}

export default Lazyload