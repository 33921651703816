export default (elements) => {
  let mousePos = null
  let orientation = null
  let tick = false
  let targets = Array.prototype.slice.call(elements)

  const orientationHandler = event => {
    if (!tick) {
      const {beta, gamma} = event
      orientation = {beta, gamma}
      window.requestAnimationFrame(() => targets.forEach(transform))
    }
    tick = true
  }

  const mouseHandler = event => {
    if (!tick) {
      const {clientX, clientY} = event
      mousePos = {clientX, clientY}
      window.requestAnimationFrame(() => targets.forEach(transform))
    }
    tick = true
  }

  const transform = el => {
    let transform, x, y
    if (orientation && orientation.gamma) {
      x = orientation.gamma * 10
      y = orientation.beta * 10
    } else if (mousePos && mousePos.clientX) {
      x = mousePos.clientX
      y = mousePos.clientY
    }
    let speed = el.dataset.gyrax !== '' && el.dataset.gyrax !== undefined ? el.dataset.gyrax : 0.2
    if (speed === 0) return
    x = Math.round(x * speed)
    y = Math.round(y * speed)
    transform = beforeTransform(el) + ` translate3d(${x}px, ${y}px, 0px) ` + afterTransform(el)
    el.style.transform = el.style.webkitTransform = transform
    tick = false
  }

  const beforeTransform = el => el.dataset.beforeTransform || ''

  const afterTransform = el => el.dataset.afterTransform || ''

  const init = () => {
    window.addEventListener('deviceorientation', orientationHandler)
    window.addEventListener('mousemove', mouseHandler)
  }

  const update = elements => {
    targets = Array.from(elements)
  }

  const destroy = () => {
    window.removeEventListener('deviceorientation', orientationHandler)
    window.removeEventListener('mousemove', mouseHandler)
  }

  return {
    init,
    update,
    destroy
  }
}