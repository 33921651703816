import CommonView from './CommonView'
import gyrax from '../libs/gyrax'

let ContactView = CommonView.extend({
  namespace: 'contact',

  onEnter: function () {
    // The new Container is ready and attached to the DOM.
    CommonView.onEnter.apply(this)

    window.app.backgroundNoise.setInitialColors()

    // Parallax with device orientation / mouse position
    const elements = this.container.querySelectorAll('[data-gyrax]')
    if (elements) {
      this.gyrax = gyrax(elements)
      this.gyrax.init()
    }
  },

  onEnterCompleted: function () {
    // The Transition has just finished.
    CommonView.onEnterCompleted.apply(this)
  },

  onLeave: function () {
    // A new Transition toward a new page has just started.
    CommonView.onLeave.apply(this)
    this.gyrax && this.gyrax.destroy()
  },

  onLeaveCompleted: function () {
    // The Container has just been removed from the DOM.
    CommonView.onLeaveCompleted.apply(this)
  }
})

export default ContactView
