import Utils from '../Utils'
import anime from 'animejs'
import PaginationLink from '../components/PaginationLink'

function projectToProject(oldContainer, newContainer, done) {
  let DOM = {}
  DOM.oldProject = oldContainer.querySelector('.Project')
  DOM.newProject = newContainer.querySelector('.Project')
  DOM.oldPaginationLinks = Array.from(DOM.oldProject.querySelectorAll('.Pagination-link'))
  DOM.newPaginationLinks = Array.from(DOM.newProject.querySelectorAll('.Pagination-link'))

  const rels = ['prev', 'next']
  let labels = null

  const start = () => {
    Utils.resetTypographicTitlePos(DOM.oldProject.querySelector('.TypographicTitle'))
    app.fixedScroll(true)
    app.scrollTop()
      .then(() => Utils.wait(200))
      .then(animateOut)
      .then(animateIn)
      .then(app.fixedScroll)
      .then(done)
  }

  const animateOut = () => {
    return new Promise((resolve, reject) => {
      let timeline = anime.timeline()

      timeline
        .add({
          targets: DOM.oldProject.querySelectorAll('.Project-heading, .Project-content, .Project-gallery'),
          duration: 500,
          delay: (el, i, l) => i * 200,
          easing: 'easeInCubic',
          translateX: 600 * -direction(),
          translateZ: 0,
          scaleX: 1.2,
          opacity: {
            value: 0,
            delay: (el, i, l) => i * 200 + 300,
            duration: 200
          }
        })
        .add({
          offset: 0,
          targets: DOM.oldProject.querySelectorAll('.TypographicTitle, .Project-floatingContent'),
          duration: 500,
          easing: 'easeInCubic',
          opacity: 0,
          translateZ: 0
        })

      // If one of new labels is null, hide it
      const labels = getPaginationLabels()
      rels.map(rel => {
        if (!labels[rel].new) {
          timeline.add({
            offset: 0,
            targets: DOM.oldPaginationLinks.filter(link => link.getAttribute('rel') === rel)[0],
            duration: 500,
            easing: 'easeInQuint',
            opacity: 0
          })
        }
      })

      timeline.begin = () => Utils.css(oldContainer, {position: 'absolute', width: '100%'})
      timeline.complete = () => resolve()
    })
  }

  const animateIn = () => {
    return new Promise((resolve, reject) => {
      let timeline = anime.timeline()

      timeline
        .add({
          targets: DOM.newProject.querySelectorAll('.Project-heading, .Project-content, .Project-gallery'),
          duration: 1000,
          delay: (el, i, l) => i * 200,
          easing: 'easeOutCubic',
          translateX: [200 * direction(), 0],
          translateZ: 0,
          scaleX: [1.2, 1],
          opacity: [0, 1],
        })
        .add({
          offset: 0,
          targets: DOM.newProject.querySelectorAll('.TypographicTitle'),
          duration: 1000,
          delay: 500,
          opacity: [0, 1],
          translateZ: 0,
          easing: 'easeOutCubic'
        })

      // If one of old labels is null, show it,
      // else play text transition
      const labels = getPaginationLabels()
      rels.map(rel => {
        const DOMLink = DOM.newPaginationLinks.filter(link => link.getAttribute('rel') === rel)[0]
        if (!DOMLink) return
        if (!labels[rel].old) {
          timeline.add({
            offset: 0,
            targets: DOMLink,
            duration: 500,
            easing: 'easeOutQuint',
            opacity: [0, 1]
          })
        } else {
          let paginationLink = new PaginationLink(DOMLink)
          paginationLink.setText(labels[rel].old, false)
          paginationLink.enable()
          setTimeout(() => paginationLink.setText(labels[rel].new), 500)
        }
      })

      timeline.begin = () => {
        Utils.css(newContainer, {visibility: 'visible'})
        Utils.css(oldContainer, {display: 'none'})
        updateBackground()
      }
      timeline.complete = () => resolve()
    })
  }

  const updateBackground = () => {
    if (app.backgroundNoise) {
      const colors = {
        background: DOM.newProject.dataset.projectBackgroundColor,
        text: DOM.newProject.dataset.projectColor
      }

      app.backgroundNoise.setColors(colors.background, colors.text)
    }
  }

  const getPaginationLabels = () => {
    if (labels) return labels
    labels = {}
    rels.map(rel => {
      const oldLink = DOM.oldPaginationLinks.filter(link => link.getAttribute('rel') === rel)[0]
      const newLink = DOM.newPaginationLinks.filter(link => link.getAttribute('rel') === rel)[0]
      labels[rel] = {
        old: oldLink && oldLink.dataset.title,
        new: newLink && newLink.dataset.title
      }
    })
    return labels
  }

  const isNext = () => app.states.lastLink.getAttribute('rel') === 'next'

  const direction = () => isNext() ? 1 : -1

  return start()
}

export default projectToProject