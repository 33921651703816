import Utils from '../Utils'
import Barba from 'barba.js'
import map from './map'

export default Barba.BaseTransition.extend({
  start: function () {
    this.newContainerLoading.then(() => {
      const from = Barba.Pjax.History.prevStatus().namespace
      const to = Barba.Pjax.History.currentStatus().namespace

      Utils.css(this.oldContainer, {pointerEvents: 'none'})
      Utils.css(this.newContainer, {pointerEvents: 'none'})

      return ((map[from] && map[from][to]) || map.default)(
        this.oldContainer,
        this.newContainer,
        () => {
          Utils.css(this.newContainer, {pointerEvents: 'auto'})
          this.done()
        }
      )
    })
  }
})