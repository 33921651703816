import Utils from '../Utils'
import anime from 'animejs'

function contactToProject(oldContainer, newContainer, done) {
  let DOM = {}
  DOM.newProject = newContainer.querySelector('.Project')

  const start = () => {
    animateOut()
      .then(animateIn)
      .then(done)
  }

  function animateOut() {
    return new Promise((resolve, reject) => {
      anime({
        targets: oldContainer,
        duration: 500,
        opacity: [1, 0],
        easing: 'easeInQuad',
        begin: () => {
          Utils.css(oldContainer, {position: 'absolute', width: '100%'})
          updateBackground()
        },
        complete: resolve
      })
    })
  }

  function animateIn() {
    return new Promise((resolve, reject) => {
      anime({
        targets: newContainer,
        duration: 1000,
        opacity: 1,
        easing: 'easeOutQuad',
        begin: () => {
          oldContainer.style.display = 'none'
          Utils.css(newContainer, {visibility: 'visible', opacity: 0})
        },
        complete: resolve
      })
    })
  }

  const updateBackground = () => {
    if (app.backgroundNoise) {
      const colors = {
        background: DOM.newProject.dataset.projectBackgroundColor,
        text: DOM.newProject.dataset.projectColor
      }

      app.backgroundNoise.setColors(colors.background, colors.text)
    }
  }

  start()
}

export default contactToProject