import Utils from '../Utils'
import Barba from 'barba.js'
import Embed from '../../../../site/plugins/embed/assets/js/src/embed'
import parax from '../libs/parax'

let CommonView = Barba.BaseView.extend({
  namespace: 'common',

  onEnter: function () {
    // The new Container is ready and attached to the DOM.
    window.app.currentView = this

    Utils.polyfillImagesFit()
    Utils.registerTextToCopy()

    // Parallax
    const elements = this.container.querySelectorAll('[data-parallax]')
    if (elements) {
      this.parallax = parax(elements)
      this.parallax.init()
    }
  },

  onEnterCompleted: function () {
    // The Transition has just finished.
  },

  onLeave: function () {
    // A new Transition toward a new page has just started.
    this.parallax && this.parallax.destroy()
  },

  onLeaveCompleted: function () {
    // The Container has just been removed from the DOM.
  }
})

export default CommonView
