import Utils from './Utils'
import BackgroundNoise from './components/BackgroundNoise'
import Lazyload from './components/Lazyload'
import jump from 'jump.js'
import Barba from 'barba.js'
import ReducerTransitions from './transitions/reducer'
import HomeView from './views/HomeView'
import ProjectsView from './views/ProjectsView'
import ProjectView from './views/ProjectView'
import ContactView from './views/ContactView'

const views = {
  Home: HomeView,
  Projects: ProjectsView,
  Project: ProjectView,
  Contact: ContactView
}

class App {
  constructor() {
    this.backgroundNoise = new BackgroundNoise({
      defaultColors: {
        text: '#252525'
      },
      noise: {
        animated: false,
        frames: 1,
        color: 0x10ffffff,
        size: 0.8
      }
    })

    this.states = {}
  }

  registerConfig() {
    window.mediaQueries = {
      mediumPhone: 320,
      largePhone: 500,
      tablet: 768,
      desktop: 992,
      largeDesktop: 1200
    }

    window.deviceList = {
      smallPhone: 'small-phone',
      mediumPhone: 'medium-phone',
      largePhone: 'large-phone',
      tablet: 'tablet',
      desktop: 'desktop',
      largeDesktop: 'large-desktop'
    }
  }

  getStates() {
    return {
      currentStatus: Barba.HistoryManager.currentStatus(),
      prevStatus: Barba.HistoryManager.prevStatus(),
      history: Barba.HistoryManager.history
    }
  }

  getCurrentUrl() {
    return Barba.Pjax.getCurrentUrl()
  }

  start() {
    this.registerConfig()

    // Init all Barba views
    for (let [namespace, view] of Object.entries(views)) {
      view.init()
    }

    // Check browser
    Utils.isFirefox() && document.body.classList.add('is-firefox')

    // Check if it's a touch device
    Utils.isTouchDevice() && document.body.classList.add('is-touch-device')

    // Set device type according to window width
    Utils.updateDeviceType()
    window.addEventListener('resize', Utils.debounce(Utils.updateDeviceType, 300))

    // Create lazyloader instance
    this.lazyload = new Lazyload()
    this.lazyload
      .update()
      .check()
      .handlers(true)

    // Launch Barba
    Barba.Pjax.Dom.getWrapper = () => document.body
    Barba.Pjax.Dom.containerClass = 'App'
    Barba.Pjax.Dom.dataNamespace = 'template'
    Barba.Pjax.getTransition = () => ReducerTransitions
    Barba.Pjax.start()
    Barba.Prefetch.init()
    Barba.Dispatcher.on('linkClicked', this.onLinkClicked.bind(this))
    Barba.Dispatcher.on('newPageReady', this.onNewPageReady.bind(this))
    Barba.Dispatcher.on('transitionCompleted', this.onTransitionCompleted.bind(this))
  }

  onLinkClicked(linkElement, event) {
    this.states.lastLink = linkElement
    Utils.setCursor('progress')
  }

  onNewPageReady(currentStatus, oldStatus, DOMElement) {
  }

  onTransitionCompleted(currentStatus, oldStatus) {
    this.lazyload
      .update()
      .check()
    Utils.setCursor('auto')
  }

  fixedScroll(enabled = false) {
    enabled ?
      document.body.classList.add('fixed') :
      document.body.classList.remove('fixed')
  }

  scrollTop() {
    return new Promise((resolve, reject) => {
      jump(document.body, {
        duration: distance => Math.min(Math.abs(distance), 1500),
        callback: resolve
      })
    })
  }
}

export default App
