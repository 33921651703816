import charmer from '../libs/charmer'

class Counter {
  constructor(el) {
    if (!el) {
      throw 'Counter needs an element to be instantiated'
      return
    }

    this.DOM = {el: el}
    this.states = {
      currentValues: {
        indicator: null,
        total: null
      }
    }
    this.animations = {}
    this.next = {
      animations: {},
      values: {}
    }
  }

  getElement(type) {
    if (type !== 'indicator' && type !== 'total') {
      throw 'Counter: type must be \'indicator\' or \'total\''
      return
    }
    return this.DOM.el.querySelector(`.Counter-${type}`)
  }

  setText(type, newValue, animated = true) {
    if (!animated) {
      this.getElement(type).innerHTML = newValue
      this.states.currentValues[type] = newValue
      return
    }

    delete this.next.animations[type]
    delete this.next.values[type]

    let options = {
      distance: newValue >= this.states.currentValues.indicator ? -10 : 10,
      complete: this.onAnimationCompleted.bind(this, type, newValue)
    }

    if (this.animations[type] && this.animations[type].isPlaying()) {
      this.next.animations[type] = charmer.bind(this, this.getElement(type), newValue, options)
      this.next.values[type] = newValue
    } else if (newValue !== this.states.currentValues[type]) {
      this.animations[type] = charmer(this.getElement(type), newValue, options)
    }
  }

  onAnimationCompleted(type, newValue) {
    if (this.next.animations[type] && this.next.values[type] !== newValue) {
      this.animations[type] = this.next.animations[type]()
      delete this.next.animations[type]
      delete this.next.values[type]
    }

    this.states.currentValues[type] = newValue
  }
}

export default Counter