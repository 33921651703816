import CommonView from './CommonView'
import ProjectSlider from '../components/ProjectSlider'

let ProjectsView = CommonView.extend({
  namespace: 'projects',

  onEnter: function () {
    // The new Container is ready and attached to the DOM.
    CommonView.onEnter.apply(this)

    const DOMSlider = this.container.querySelector('.ProjectSlider')
    if (DOMSlider) {
      this.projectSlider = new ProjectSlider(DOMSlider)
    }
  },

  onEnterCompleted: function () {
    // The Transition has just finished.
    CommonView.onLeaveCompleted.apply(this)
  },

  onLeave: function () {
    // A new Transition toward a new page has just started.
    CommonView.onLeave.apply(this)
    this.projectSlider.destroy()
    delete this.projectSlider
  },

  onLeaveCompleted: function () {
    // The Container has just been removed from the DOM.
    CommonView.onLeaveCompleted.apply(this)
  }
})

export default ProjectsView
