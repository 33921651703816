import Utils from '../Utils'
import disableScroll from 'disable-scroll'

class Gallery {
  constructor(el) {
    if (!el) {
      throw 'Gallery needs an element to be instantiated'
      return
    }

    this.DOM = {el: el}
    this.states = {
      focused: false
    }

    this.bindEvents()
  }

  onClick(event) {
    let el = event.currentTarget
    !el.classList.contains('is-focused') ? this.zoomIn(el) : this.zoomOut(el)
  }

  onResize(event, el) {
    this.zoomOut(el)
  }

  zoomIn(el) {
    if (!this.states.focused) {
      el.classList.add('is-focused')
      Utils.css(el, this.getZoomStyle(el))
      this.states.focused = true

      function fn(event) {
        window.removeEventListener('resize', cb)
        this.onResize(event, el)
      }

      let cb = fn.bind(this)
      window.addEventListener('resize', cb)

      disableScroll.on({disableScrollbar: false})
    }
  }

  zoomOut(el) {
    el.classList.remove('is-focused')
    el.style.transform = el.style['-webkit-transform'] = el.dataset.transform
    delete el.dataset.transform
    this.states.focused = false
    setTimeout(() => {
      el.style.zIndex--
      disableScroll.off()
    }, 500)
  }

  getZoomStyle(el) {
    let rect = el.getBoundingClientRect(),
      originalTransform = el.style.transform,
      x, y, scale, transform = originalTransform,
      index = el.style.zIndex || 0,
      img = el.querySelector('img')

    index = parseInt(index) + 1

    scale = rect.width > rect.height ?
      Math.min(img.getAttribute('width'), window.innerWidth - 50) / rect.width :
      Math.min(img.getAttribute('height'), window.innerHeight - 50) / rect.height
    scale = Math.max(scale.toFixed(2), 1)

    el.dataset.transform = originalTransform

    x = ((window.innerWidth / 2) - rect.x) - (rect.width / 2)
    y = ((window.innerHeight / 2) - rect.y) - (rect.height / 2)
    transform += ` translate(${x}px, ${y}px) scale(${scale})`

    debugger

    return {
      transform: transform,
      '-webkit-transform': transform,
      zIndex: index
    }
  }

  bindEvents() {
    this.onClickListener = this.onClick.bind(this)

    Array
      .from(this.DOM.el.childNodes)
      .map(galleryItem => {
        if (galleryItem.classList && galleryItem.classList.contains('Gallery-item--image')) {
          galleryItem.addEventListener('click', this.onClickListener)
        }
      })
  }

  unbindEvents() {
    Array
      .from(this.DOM.el.childNodes)
      .map(galleryItem => galleryItem.removeEventListener('click', this.onClickListener))

    delete  this.onClickListener
  }

  destroy() {
    this.unbindEvents()
  }
}

export default Gallery