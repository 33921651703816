import Utils from '../Utils'
import anime from 'animejs'

function projectsToProject(oldContainer, newContainer, done) {
  const start = () => {
    app.fixedScroll(true)
    Utils.wait(500)
      .then(animateOut)
      .then(animateIn)
      .then(() => Utils.wait(200))
      .then(app.fixedScroll)
      .then(done)
  }

  const animateOut = () => {
    return new Promise((resolve, reject) => {
      let timeline = anime.timeline()

      timeline
        .add({
          targets: oldContainer.querySelector('.ProjectSlider-item.active'),
          duration: 500,
          easing: 'easeInQuad',
          translateY: -600,
          translateZ: 0,
          scaleY: 1.5,
          opacity: {
            value: 0,
            delay: 400,
            duration: 200
          },
        })

      timeline.begin = () => Utils.css(oldContainer, {position: 'absolute', width: '100%'})
      timeline.complete = () => resolve()
    })
  }

  const animateIn = () => {
    return new Promise((resolve, reject) => {
      anime({
        targets: newContainer.querySelectorAll('.Project-heading, .Project-content'),
        duration: 1000,
        delay: (el, i, l) => i * 200,
        easing: 'easeOutQuart',
        translateY: [200, 0],
        translateZ: 0,
        scaleY: [1.5, 1],
        opacity: [0, 1],
        begin: () => {
          Utils.css(newContainer, {visibility: 'visible'})
          Utils.css(oldContainer, {display: 'none'})
        },
        complete: resolve
      })

    })
  }

  return start()
}

export default projectsToProject