import charmer from '../libs/charmer'

class PaginationLink {
  constructor(el) {
    if (!el) {
      throw 'PaginationLink needs an element to be instantiated'
      return
    }

    this.DOM = {el: el}
    this.states = {
      isActive: () => !this.DOM.el.classList.contains('disabled')
    }
  }

  getType() {
    return this.DOM.el.getAttribute('rel')
  }

  getLabel() {
    return this.DOM.el.querySelector('.Pagination-label')
  }

  setText(newValue, animated = true) {
    if (!animated || !this.states.isActive() || window.innerWidth <= mediaQueries.tablet) {
      this.getLabel().innerHTML = this.states.currentValue = newValue
      return
    }

    delete this.next

    let options = {
      distance: this.getType() === 'next' ? 20 : -20,
      alignTo: 'right',
      complete: this.onAnimationCompleted.bind(this, newValue)
    }

    if (this.animation && this.animation.isPlaying()) {
      this.next = {
        animation: charmer.bind(this, this.getLabel(), newValue, options),
        value: newValue
      }
    } else if (newValue !== this.states.currentValue) {
      this.animation = charmer(this.getLabel(), newValue, options)
    }
  }

  onAnimationCompleted(newValue) {
    if (this.next && this.next.animation && this.next.value !== newValue) {
      this.animation = this.next.animation()
      delete this.next
    }

    this.states.currentValue = newValue
  }

  enable() {
    this.DOM.el.classList.remove('disabled')
  }

  disable() {
    this.DOM.el.classList.add('disabled')
  }

  destroy() {
    delete this
  }
}

export default PaginationLink