import Utils from '../Utils'
import anime from 'animejs'
import PaginationLink from '../components/PaginationLink'

function projectToProjects(oldContainer, newContainer, done) {
  let DOM = {}
  DOM.oldProject = oldContainer.querySelector('.Project')
  DOM.sliderItems = newContainer.querySelectorAll('.ProjectSlider-item')

  const start = () => {
    Utils.resetTypographicTitlePos(DOM.oldProject.querySelector('.TypographicTitle'))
    app.fixedScroll(true)
    app.scrollTop()
      .then(() => Utils.wait(200))
      .then(animateOut)
      .then(animateIn)
      .then(app.fixedScroll)
      .then(done)
  }

  const animateOut = () => {
    return new Promise((resolve, reject) => {
      let oldPaginationLink, newPaginationLink
      let timeline = anime.timeline()

      timeline
        .add({
          targets: DOM.oldProject.querySelectorAll('.Project-heading, .Project-content, .Project-gallery'),
          duration: 500,
          delay: (el, i, l) => (l - i) * 200,
          easing: 'easeInQuart',
          translateY: 200,
          translateZ: 0,
          scaleY: 1.5,
          opacity: 0,
          begin: anim => anim.animatables[2] && Utils.css(anim.animatables[2].target, {transformOrigin: 'top'})

        })
        .add({
          offset: 0,
          targets: DOM.oldProject.querySelectorAll('.TypographicTitle, .Project-floatingContent'),
          duration: 500,
          easing: 'easeInCubic',
          opacity: 0,
          translateZ: 0
        })

      Array.from(DOM.oldProject.querySelectorAll('.Pagination-link')).map(link => {
        switch (link.getAttribute('rel')) {
          case 'prev':
            timeline.add({
              offset: 0,
              targets: link,
              duration: 500,
              easing: 'easeInQuint',
              opacity: 0
            })
            break
          case 'next':
            const label = getPaginationLabel()
            if (label) {
              if (label !== link.dataset.title) {
                oldPaginationLink = new PaginationLink(link)
                oldPaginationLink.setText(label)
              }

              newPaginationLink = new PaginationLink(newContainer.querySelector('.Pagination-link--next'))
              newPaginationLink.enable()
              newPaginationLink.setText(label, false)
            }
            break
        }
      })

      timeline.begin = () => Utils.css(oldContainer, {position: 'absolute', width: '100%'})
      timeline.complete = () => resolve()
    })
  }

  const animateIn = () => {
    return new Promise((resolve, reject) => {
      let timeline = anime.timeline()

      timeline
        .add({
          targets: DOM.sliderItems[0],
          duration: 1500,
          easing: 'easeOutQuint',
          translateY: [-600, 0],
          translateZ: 0,
          scaleY: [1.5, 1],
          opacity: [0, 1]
        })

      timeline.begin = () => {
        Utils.css(newContainer, {visibility: 'visible'})
        Utils.css(oldContainer, {display: 'none'})
        updateBackground()
      }
      timeline.complete = () => resolve()
    })
  }

  const updateBackground = () => {
    if (app.backgroundNoise) {
      const colors = {
        background: DOM.sliderItems[0].dataset.projectBackgroundColor,
        text: DOM.sliderItems[0].dataset.projectColor,
      }
      app.backgroundNoise.setColors(colors.background, colors.text)
    }
  }

  const getPaginationLabel = () => DOM.sliderItems[1].dataset.projectTitle

  start()
}

export default projectToProjects