export default (elements) => {
  let y = 0
  let tick = false
  let targets = Array.prototype.slice.call(elements)

  const handler = event => {
    if (!tick) {
      window.requestAnimationFrame(() => targets.forEach(transform))
      y = window.pageYOffset
    }
    tick = true
  }

  const transform = el => {
    let speed = el.dataset.parallax !== '' && el.dataset.parallax !== undefined ? 1 - el.dataset.parallax : 0.2
    if (speed === 0) return
    let transform = Math.round(y * speed)
    transform = beforeTransform(el) + ` translate3d(0px, ${transform}px, 0px) ` + afterTransform(el)
    el.style.transform = el.style.webkitTransform = transform
    tick = false
  }

  const beforeTransform = el => el.dataset.beforeTransform || ''

  const afterTransform = el => el.dataset.afterTransform || ''

  const init = () => window.addEventListener('scroll', handler)

  const update = elements => {
    targets = Array.from(elements)
  }

  const destroy = () => window.removeEventListener('scroll', handler)

  return {
    init,
    update,
    destroy
  }
}