import Utils from '../Utils'
import anime from 'animejs'

function fade(oldContainer, newContainer, done) {
  function start() {
    animateOut()
      .then(animateIn)
      .then(done)
  }

  function animateOut() {
    return new Promise((resolve, reject) => {
      anime({
        targets: oldContainer,
        duration: 500,
        opacity: [1, 0],
        easing: 'easeInQuad',
        begin: () => Utils.css(oldContainer, {position: 'absolute', width: '100%'}),
        complete: resolve
      })
    })
  }

  function animateIn() {
    return new Promise((resolve, reject) => {
      anime({
        targets: newContainer,
        duration: 500,
        opacity: 1,
        easing: 'easeOutQuad',
        begin: () => {
          oldContainer.style.display = 'none'
          Utils.css(newContainer, {visibility: 'visible', opacity: 0})
        },
        complete: resolve
      })
    })
  }

  return start()
}

export default fade